<template>
  <div>
    <div class="pb-3">
      <h4 class="bold"> ANIMAL RESCUE REPORT</h4>
    </div>

    <div id="animalMap"></div>
    <div>
      <vue-element-loading
        :active="appLoading"
        :is-full-screen="true"
        background-color="#FFFFFF"
        color="blue"
        spinner="mini-spinner"
      />
      <ServerError v-if="ServerError" />

      <Details :storage="MainData"/>
    </div>
  </div>
</template>
<script
    src="https://maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyBTkuPaji75FkC_WBegL4cupA9_HJWNAuE&libraries=visualization,drawing">
</script>
<script>
import axios from "axios";
import Details from './dataPage';
export default {
  components: {
    Details
  },
  data() {
    return {
      MainData:[],
      defaultLAt: 0,
      defaultLON: 0,
      icons: "",
      totalLength: 0,
      page: 1,
      appLoading: false,
      ServerError: false,
      user: [],
      records: [],
      gmap: null,
      pagelength: 0,
      allData: [],
      headers: [
        { text: "Date", value: "create_date" },
        {
          text: "Boat name",
          align: "start",
          sortable: false,
          value: "boatname",
        },
        { text: "Boat No", sortable: false, value: "boatno" },
        { text: "Spot", sortable: false, value: "field" },
        { text: "Expected length (ft)", value: "length" },
      ],
      //map data
      latt: null,
      lonn: null,
    };
  },
  watch: {
    page() {
      this.getData();
    },
  },
  mounted() {
    this.getData();
    
  
  },
  methods: {
    ChangeFormateDate(oldDate) {
      if (oldDate) {
        var newdateFormat = oldDate.split("T");
        var splitDate = newdateFormat[0].split("-");
        if (splitDate.count == 0) {
          return null;
        }
        var year = splitDate[0];
        var month = splitDate[1];
        var day = splitDate[2];
        return month + "/" + day + "/" + year;
      }
    },
    getMap() {
      this.gmap = new google.maps.Map(document.getElementById("animalMap"), {
        center: { lat: this.latt, lng: this.lonn },
        // center: { lat: 0, lng:0 },
        // center: { lat: this.defaultLON, lng: this.defaultLAt },
        mapTypeId: "satellite",
        zoom: 16,
      });

        this.icons = {
              
                 url: require("../../assets/images/markers/greenMarker.png"),
                size: new google.maps.Size(100, 60),
                scaledSize: new google.maps.Size(20, 30),
                anchor: new google.maps.Point(0, 50),
    
              };

    
        this.marker = new google.maps.Marker({
          position: { lat: this.latt, lng: this.lonn },
          map: this.gmap,
          icon: this.icons,
        });
      
    },

    getData() {
      this.appLoading = true;
      axios({
        url: "/animal/rescue/detail/admin",
        method: "post",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: this.$route.query.id,
          page: this.page,
          limit: 25,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            console.log(response.data.status);
            this.latt = response.data.data.animalLocation[1];
            this.lonn = response.data.data.animalLocation[0];


            this.MainData = response.data.data;
             this.getMap()
          } else {
            this.showsnackbar = true;
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
#animalMap {
  height: 400px;
  width: 100%;
  margin: 0px;
  padding: 0px;
}
</style>
